.LegalDocuments {
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.LegalDocuments table {
    border-collapse: collapse;
    width: 100%;
}

.LegalDocuments tr {
    border-top: 1px solid #ccc;
}

.LegalDocuments th {
    padding-top: 16px;
    padding-bottom: 16px;
}

.LegalDocuments .Headers {
    font-weight: bold;
}